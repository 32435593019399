const priceContracts:
    {
        tmdsAddress: string,
        busdAddress: string,
        bnbAddress: string,
        tmdsBnbLpAddress: string,
        bnbBusdLpAddress: string
    } = {
    tmdsAddress: '0x01DA6501d1083464f9a2C9a8cacF89F2dc160A97',
    busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    bnbAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tmdsBnbLpAddress: '0x01DA6501d1083464f9a2C9a8cacF89F2dc160A97',
    bnbBusdLpAddress: '0x51BA779FdD1968d08f003f08024D0A3b7531438a'
}

export default priceContracts
