// import { useEffect, useState } from 'react'

// type ApiResponse = {
//   data: {
//     [address: string]: {
//       name: string
//       symbol: string
//       price:string
//       price_BNB:string
//     }
//   }
//   updated_at: string
// }

// /**
//  * Due to Cors the api was forked and a proxy was created
//  * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
//  */
// const api = 'https://api.pantherswap.info/api/tokens'

// const useGetPriceData = () => {
//   const [data, setData] = useState<ApiResponse | null>(null)

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(api)
//         const res: ApiResponse = await response.json()

//         setData(res)
//       } catch (error) {
//         console.error('Unable to fetch price data:', error)
//       }
//     }

//     fetchData()
//   }, [setData])

//   return data
// }

// export default useGetPriceData

import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/tokenPriceContracts'

// type ApiResponse = {
//   prices: {
//     [key: string]: string
//   }
//   update_at: string
// }

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
// const api = 'https://api.pancakeswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (multicallContract) {
          const { tmdsAddress, bnbAddress, busdAddress, tmdsBnbLpAddress, bnbBusdLpAddress } = priceContracts;
          const calls = [
            [tmdsAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [tmdsBnbLpAddress])],
            [bnbAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [tmdsBnbLpAddress])],
            [bnbAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [bnbBusdLpAddress])],
            [busdAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [bnbBusdLpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [tmdsAmount, bnbAmount1, bnbAmount2, busdAmount] = result.map(r => ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const tmds = new BigNumber(tmdsAmount);
          const bnb1 = new BigNumber(bnbAmount1);
          const bnb2 = new BigNumber(bnbAmount2);
          const busd = new BigNumber(busdAmount);
          const tmdsPrice = bnb1.div(tmds).times(busd).div(bnb2).div(new BigNumber(10).pow(8)).toNumber();  // TMDS decimals 8
          setData(tmdsPrice<50?tmdsPrice:0.00125)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export default useGetPriceData
